import { Routes, Route } from "react-router-dom";
import LandingPage from "../landing/LandingPage";
import ChooseaService from "../chooseAService/ChooseaService";

function Root() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/choose-a-service" element={<ChooseaService />} />
      </Routes>
    </>
  );
}

export default Root;
