import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Abstract from "../../assets/images/abstract2.png";
import Legall from "../../assets/images/Legall.svg";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Logo from "../../assets/images/File_Now_Logo.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ChooseaService() {
  const services = [
    "ITR Filing",
    "Annual Filing - Pvt Ltd",
    "ROC Filing",
    "Annual Filing - Others",
  ];
  let navigate = useNavigate();
  const [service, setService] = useState("");
  const [mobile, setMobile] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleService = (event) => {
    setService(event.target.value);
  };

  const handleMobile = (event) => {
    setMobile(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = { service, mobile };
    console.log("data", data);
    await fetch(
      "https://script.google.com/macros/s/AKfycbyY8-T8R8QZypo2hOxLB_26n17oJKfdk15dgznVrlKx0Zl7q4wlyicsQg9Kt2IhlCbf/exec?action=addUser",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )
      .then((data) => handleClick())
      .catch((err) => alert("failed to submit, pls try again later"));
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Successfully Submitted
        </Alert>
      </Snackbar>
      <div className="background">
        <div className="mainContainer">
          <div className="flexrow">
            <div className="chooseAServiceImg">
              <img alt="content" src={Legall} className="legallImg" />
            </div>

            <div className="chooseAService">
              <div className="header">Choose a service</div>
              <div className="inputContainer">
                <a className="a1">Phone no</a>
                <input className="phoneInput" onInput={handleMobile} />
              </div>
              <div className=""></div>
              <div className="inputContainer">
                <a className="a1">Services</a>
                <Select
                  sx={{ fontSize: 20 }}
                  value={service}
                  className="phoneInput"
                  displayEmpty
                  // label="Services"
                  onChange={handleService}
                  // placeholder={"Services"}
                >
                  {services.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="btn-section">
                <Button variant="contained" onClick={handleSubmit}>
                  Register
                </Button>
                {/* <Button variant="contained" onClick={()=> {navigate("/");}}>Back</Button> */}
              </div>
            </div>
          </div>
        </div>
        {window.screen.width > 700 ? (
          <>
            <p className="watermark"> @ Atlas.Privacy & terms</p>
            <img alt="content" src={Abstract} className="abstract" />
          </>
        ) : null}
        <div className="logoContainer">
          <img
            alt="content"
            src={Logo}
            className="logo"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ChooseaService;
